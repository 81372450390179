<!-- <div class="page-border" data-wow-duration="0.7s" data-wow-delay="0.2s">
  <div
    class="top-border wow fadeInDown animated"
    style="visibility: visible; animation-name: fadeInDown"
  ></div>
  <div
    class="right-border wow fadeInRight animated"
    style="visibility: visible; animation-name: fadeInRight"
  ></div>
  <div
    class="bottom-border wow fadeInUp animated"
    style="visibility: visible; animation-name: fadeInUp"
  ></div>
  <div
    class="left-border wow fadeInLeft animated"
    style="visibility: visible; animation-name: fadeInLeft"
  ></div>
</div> -->
<!--Main Content Area-->
<div class="main-container">
  <div class="scroll-container scroll-container-expand-lg shadow-none">
    <!-- Brand -->
    <a
      class="navbar-brand font-weight-bolder ms-sm-3"
      href="https://www.bubblebees-nft.com"
      target="_blank"
      rel="noreferrer"
    >
      <img alt="logo" src="../assets/images/logo.png"
    /></a>

    <!-- Menu -->
    <div class="navbar">
      <div class="navbar-nav">
        <!-- Home -->
        <div
          class="step-section"
          [ngClass]="{ active: activeSection == 1 }"
          pageScroll
          (click)="scrollTo(home, 1)"
        >
          <div class="vertical-nav">
            <div class="section-name">Home</div>
          </div>
        </div>

        <!-- Roadmap -->
        <div
          class="step-section"
          [ngClass]="{ active: activeSection == 2 }"
          pageScroll
          (click)="scrollTo(roadmap, 2)"
        >
          <div class="vertical-nav">
            <div class="section-name">Roadmap</div>
          </div>
        </div>

        <!-- Team -->
        <div
          class="step-section"
          [ngClass]="{ active: activeSection == 3 }"
          pageScroll
          (click)="scrollTo(team, 3)"
        >
          <div class="vertical-nav">
            <div class="section-name">Team</div>
          </div>
        </div>

        <!--Our Story -->
        <div
          class="step-section"
          [ngClass]="{ active: activeSection == 4 }"
          pageScroll
          (click)="scrollTo(ourstory, 4)"
        >
          <div class="vertical-nav">
            <div class="section-name">Our Story</div>
          </div>
        </div>

        <!-- We Support -->
        <div
          class="step-section"
          [ngClass]="{ active: activeSection == 5 }"
          pageScroll
          (click)="scrollTo(wesupport, 5)"
        >
          <div class="vertical-nav">
            <div class="section-name">We Support</div>
          </div>
        </div>

        <!-- Telegram -->
        <div class="step-media">
          <div class="social-icon">
            <a
              href="https://t.me/+6mImakKChy02ZGRk"
              class="text-dark text-uppercase text-lg mb-2 mb-lg-0"
              target="_blank"
              title="Telegram"
              ><i class="fa fa-telegram fa-1x"></i
            ></a>
          </div>
        </div>

        <!-- Twitter -->
        <div class="step-media">
          <div class="social-icon">
            <a
              target="_blank"
              title="Twitter"
              href="https://twitter.com/Bubblebees_NFT"
            >
              <i class="fa fa-twitter fa-1x"></i>
            </a>
          </div>
        </div>

        <!-- Instagram -->
        <div class="step-media">
          <div class="social-icon">
            <a
              href="https://www.instagram.com/bubblebeesnft"
              class="text-dark text-uppercase text-lg mb-2 mb-lg-0"
              target="_blank"
              title="Instagram"
              ><i class="fa fa-instagram" aria-hidden="true"></i>
            </a>
          </div>
        </div>
        <!-- Discord -->
        <div class="step-media">
          <div class="social-icon">
            <a
              href="http://www.discord.com/BubblebeesNFT"
              target="_blank"
              title="Discord"
            >
              <fa-icon [icon]="['fab', 'discord']"></fa-icon>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Home -->
  <div id="1" #home class="scroll-outer">
    <div class="card-container">
      <header id="banner" class="scrollto clearfix" data-enllax-ratio=".5">
        <!--Banner Content-->
        <div id="banner-content" class="row clearfix">
          <div class="col-38">
            <div class="section-heading">
              <h1>{{ header.heading }}</h1>
              <h4>{{ header.headingtext }}</h4>

              <div class="banner-media">
                <!-- Telegram -->
                <div class="social-icon">
                  <a
                    href="https://t.me/+6mImakKChy02ZGRk"
                    class="text-dark text-uppercase text-lg mb-2 mb-lg-0"
                    target="_blank"
                    title="Telegram"
                    ><i class="fa fa-telegram fa-2x"></i
                  ></a>
                </div>

                <!-- Tweeter -->
                <div class="social-icon">
                  <a
                    target="_blank"
                    title="Twitter"
                    href="https://twitter.com/Bubblebees_NFT"
                  >
                    <i class="fa fa-twitter fa-2x"></i>
                  </a>
                </div>

                <!-- Instagram -->
                <div class="social-icon">
                  <a
                    href="https://www.instagram.com/bubblebeesnft"
                    class="text-dark text-uppercase text-lg mb-2 mb-lg-0"
                    target="_blank"
                    title="Instagram"
                    ><i class="fa fa-instagram fa-2x" aria-hidden="true"></i>
                  </a>
                </div>

                <!-- Discord -->
                <div class="social-icon">
                  <a
                    href="https://www.discord.com/BubblebeesNFT"
                    class="text-dark text-uppercase text-lg mb-2 mb-lg-0"
                    target="_blank"
                    title="Discord"
                  >
                    <fa-icon [icon]="['fab', 'discord']" size="2x"></fa-icon>
                  </a>
                </div>
              </div>
            </div>

            <!--Call to Action-->
            <a
              href="https://xoxno.com/collection/BUBBS-c11eb5"
              target="_blank"
              class="button"
              >{{ header.buttontext }}</a
            >
            <!--End Call to Action-->
          </div>
        </div>
        <!--End of Row-->
      </header>
    </div>
  </div>

  <!-- Roadmap -->
  <div id="2" #roadmap class="scroll-outer">
    <div class="card-container">
      <!--roadmap Tables-->
      <section
        id="roadmap-content"
        class="secondary-color text-center scrollto clearfix"
      >
        <div class="row clearfix">
          <div class="section-heading">
            <h2 class="section-title">Roadmap 2023</h2>
          </div>

          <!--roadmap Block-->
          <div class="roadmap-block col-3 wow fadeInUp" data-wow-delay="0.4s">
            <div class="roadmap-block-content">
              <h3>[I] Wake Up</h3>
              <!-- <p class="roadmap-sub">Wake Up</p> -->
              <ul>
                <li>
                  After a long winter the bublebees woke up in a different
                  world. The distruction of nature left them disordered and
                  unsettled. Our mission is to set up a place where they can
                  rebuild what was lost.
                </li>
                <li>Website launch</li>
                <li>
                  Partnership with association protecting bees, helping projects
                  that connects nature, culture and customs
                </li>
                <li>Competitions, recompensating community</li>
                <li>Launch of limited edition BlubbleBees Nfts</li>
                <li>First 1000 Common, Rare and Super rare colony of bees</li>
                <li>Work in progress for our platform</li>
                <li>More to come ...</li>
              </ul>
              <a
                href="https://xoxno.com/collection/BUBBS-c11eb5"
                target="_blank"
                class="button"
                >MINT BEES</a
              >
            </div>
          </div>

          <div
            class="roadmap-block featured col-3 wow fadeInUp"
            data-wow-delay="0.6s"
          >
            <div class="roadmap-block-content">
              <h3>[II] Reconstruction</h3>
              <!-- <p class="roadmap-sub">Reconstruction</p> -->
              <ul>
                <li>
                  The community now have the resources to build their hive. They
                  organize and work hard to create their homes.
                </li>
                <li>
                  Build up our platform that will integrate marketing value for
                  small bussinesses, craftsmen, festivals and lots of good deals
                  for our Nfts holders
                </li>
                <li>
                  Launch limitless edition of Golden Buzzer (those are intended
                  for people that want to promote their business, product, sale
                  offers)
                </li>
                <li>Launch second edition of 1000 Nfts BubbleBees</li>
                <li>
                  New benefits along the way for Nft holders from both phases
                </li>
                <li>Staking feature</li>
                <li>Engage, analyze and scale our community</li>
                <li>Involve more partners into our vision</li>
                <li>More to come ...</li>
              </ul>
              <a
                href="https://xoxno.com/collection/BUBBS-c11eb5"
                target="_blank"
                class="button"
                >MINT BEES</a
              >
            </div>
          </div>

          <div class="roadmap-block col-3 wow fadeInUp" data-wow-delay="0.8s">
            <div class="roadmap-block-content">
              <h3>[III] Thrive</h3>
              <!-- <p class="roadmap-sub">Thrive</p> -->
              <ul>
                <li>
                  The bublebees had overcome their threat, the habitat is
                  growing and they happily return to their buzzing glory.
                </li>
                <li>Release last collection of 1000 Nfts</li>
                <li>Staking feature</li>
                <li>Buid up more on the platform</li>
                <li>
                  Taking further the collaboration with our partners. Atract
                  more businesses to list their deals.
                </li>
                <li>Bring bees and habitats into the virtual world</li>
                <li>Launch of BubbleBees marchandise</li>
              </ul>
              <a
                href="https://xoxno.com/collection/BUBBS-c11eb5"
                target="_blank"
                class="button"
                >MINT BEES</a
              >
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>

  <div id="3" #team class="scroll-outer">
    <div class="card-container">
      <div
        id="team-content"
        class="scrollto text-center"
        data-enllax-ratio=".2"
      >
        <div class="row clearfix">
          <div class="section-heading">
            <h2 class="section-title">Meet our team</h2>
          </div>

          <!--Team composition-->
          <div class="test-container">
            <blockquote class="col-2 testimonial classic">
              <img
                class="avatar"
                src="../assets/images/avatar-design.png"
                alt="User"
              />
              <q
                >My goal as a designer for this NFT project is to create digital
                assets that are not only visually appealing but also valuable
                and meaningful to our audience. I believe that NFTs have the
                potential to change the way we think about art, ownership, and
                value, and I'm thrilled to be part of a team that's at the
                forefront of this exciting new field.</q
              >
              <footer>Irina Bogdan - Designer</footer>
            </blockquote>
            <!-- End of Testimonial-->

            <!--User Testimonial-->

            <blockquote class="col-2 testimonial classic">
              <img
                class="avatar"
                src="../assets/images/avatar-dev.png"
                alt="User"
              />
              <q
                >Believing in the transformative power of blending art and
                technology. Bringing together the best of both worlds to create
                a digital realm where art flourishes and technology amplifies
                the human experience. Responsible for the technical side that
                supports the creation and storage of unique digital assets on a
                blockchain.</q
              >
              <footer>Florina Clapon - IT specialist</footer>
            </blockquote>
          </div>
          <!-- End of Testimonial-->
        </div>
      </div>
      <!--End of Testimonials-->
    </div>
  </div>

  <div id="4" #ourstory class="scroll-outer">
    <div class="card-container">
      <div
        id="savebees-content"
        class="scrollto clearfix text-center"
        data-enllax-ratio=".5"
      >
        <div class="row clearfix">
          <div class="section-heading">
            <h2 class="section-title">Our Story</h2>
          </div>
        </div>
        <!-- Our Story content -->
        <div id="right-content" class="row clearfix">
          <div class="col-61">
            <div class="section-heading">
              <div class="bees-content">
                <p class="p1">
                  Our collection reveals 3 phases of bee’s evolution, each phase
                  containing 1000 Nfts. Each NFT in the BubbleBees collection
                  represents a unique artistic interpretation of bees. The
                  collection features are crafted by passionate members about
                  both art and environmental conservation.
                </p>

                <p class="p1">
                  The NFTs in this project are more than just digital artworks.
                  They serve as a bridge between the virtual and physical
                  worlds, carrying a mission to make <b>a tangible impact on bee
                  preservation</b>. A percentage of the funds generated from the
                  minting process is allocated directly to organizations and
                  initiatives working towards preserving bee populations and
                  nature initiatives.
                </p>
                <p class="p1">
                  As the project progresses into the second phase, BubbleBees
                  introduces a value-added proposition for NFT holders. The team
                  develops <b>a dedicated platform designed to connect small
                  businesses, craftsmen and organizations with the community of
                  NFT holders</b>. The platform allows these entities to list and
                  promote their products, services, and special offers
                  exclusively to NFT holders within the BubbleBees ecosystem.
                </p>
                <p class="p1">
                  BubbleBees NFTs offer unique benefits and experiences for
                  both holders and producers:
                </p>

                <div class="container">
                  <div class="box">
                    <h4>Holders Benefits</h4>
                    <div class="bold">Exclusive Access to Offerings:</div>
                    NFT holders have exclusive access to the products, services,
                    and special offers listed on platform. Unique traits of the
                    Nfts can reveal access to a selected group of rewards.
                    <div class="bold">Discounts and Limited Editions:</div>
                    you have the chance to acquire products at a discounted
                    price or access limited-edition items before they are
                    available to the general public.
                    <div class="bold">Staking and Rewards:</div>
                    the project will introduce a staking mechanism where you can
                    stake your NFTs to earn additional rewards.
                    <div class="bold">Supporting a Cause:</div>
                    by holding NFTs within the BubbleBees ecosystem, you
                    directly support the cause of bee conservation. Your
                    involvement as an NFT holder contributes to the
                    sustainability of bee populations and ecological balance.
                    <div class="bold">Potential Value Appreciation:</div>
                    depending on the success and popularity of the BubbleBees
                    project, there is a possibility of the value of your NFTs
                    appreciating over time. As the ecosystem grows, more
                    individuals and businesses may join, increasing the demand
                    for BubbleBees NFTs. This potential value appreciation adds
                    a financial aspect to your NFT holdings.
                  </div>
                  <div class="box">
                    <h4>Producers Benefits</h4>
                    <div class="bold">
                      Increased Visibility and Brand Exposure:
                    </div>
                    being part of the ecosystem allows producers and small
                    businesses to gain exposure and increase brand awareness.<br />
                    <div class="bold">Stimulation of Sales :</div>
                    the presence of a vibrant community of NFT holders creates
                    opportunities for increased sales.<br />
                    <div class="bold">Rewards and Incentives:</div>
                    producers and small businesses can offer exclusive rewards
                    and incentives to NFT holders. These can include limited
                    editions, special discounts, early access to new releases,
                    or even customized products tailored specifically for the
                    community. Such incentives can drive customer loyalty and
                    boost sales.
                    <div class="bold">Enhanced Trust and Credibility:</div>
                    by being associated with Bubblebees and its mission of bee
                    conservation, producers and small businesses can enhance
                    their trustworthiness and credibility among environmentally
                    conscious consumers. NFT holders are more likely to support
                    businesses that align with their values and contribute to a
                    cause they care about.
                  </div>
                  <!-- <div class="box">
                    <h4>Digital and Physical Artifacts</h4>
                    <p>
                      Some NFTs in the collection come bundled with additional
                      perks, such as high-resolution digital versions of the
                      artwork for personal use, and the possibility to receive a
                      physical art print of the NFT, enhancing the connection
                      between the digital and physical realms.
                    </p>
                  </div> -->
                </div>

                <p class="p1">
                  By encompassing these elements, BubbleBees has the
                  potential to inspire and mobilize individuals, communities,
                  and businesses towards bee conservation. It has the ability to
                  create a movement by combining a critical cause, community
                  engagement, collaborative efforts, education, tangible impact,
                  financial incentives, and scalability.
                </p>
              </div>
            </div>
          </div>
        </div>

        <!-- End Our Story content -->
      </div>
    </div>
  </div>

  <div id="5" #wesupport class="scroll-outer">
    <div class="card-container">
      <div
        id="support-content"
        class="secondary-color text-center scrollto clearfix"
      >
        <div class="row clearfix">
          <div class="section-heading">
            <h2 class="section-title">We support</h2>
          </div>
          <h4>Communities with shared values and ideals</h4>
          <!-- We support content -->
          <!-- Via Transilvanica -->
          <div class="wesupport-container col-2">
            <blockquote class="row partners classic">
              <div class="support-item">
                <img
                  class="avatar"
                  src="../assets/images/ViaTransilvanica.png"
                  alt="User"
                />
              </div>
              <div class="support-item">
                <p>
                  Via Transilvanica - „The road that unites” is a long-distance
                  trail of 1,400 kilometers that crosses Romania diagonally,
                  from Putna to Drobeta Turnu Severin. Whether you're an avid
                  hiker, a cycling enthusiast, or an equestrian lover, this
                  trail offers an unforgettable experience. Via Transilvanica
                  serves as a bridge connecting communities, promoting unity,
                  and fostering a deeper understanding of Romania's rich
                  cultural heritage. A project by Tăşuleasa Social, an NGO that
                  deals with environmental preservation, education, social
                  welfare, cultural development, and more.
                </p>
                <footer>
                  <a href="https://www.viatransilvanica.com/" target="_blank"
                    ><i class="fa fa-arrow-right" aria-hidden="true"></i> Via
                    Transilvanica</a
                  >
                </footer>
              </div>
            </blockquote>
          </div>

          <!-- AppAps -->
          <div class="wesupport-container col-2">
            <blockquote class="row partners classic">
              <div class="support-item">
                <img
                  class="avatar"
                  src="../assets/images/logo-appaps.png"
                  alt="User"
                />
              </div>
              <div class="support-item">
                <p>
                  Association for the Protection of Wild Bees and Pollinators.
                  With a mission to raise awareness about the invaluable
                  contribution of bees to our environment, through engaging and
                  immersive workshops, they bring bees right in front of the
                  eyes of children and adults alike. Through their projects,
                  this organization is sowing the seeds of compassion and
                  cultivating a profound appreciation for the crucial role that
                  bees play in our world. By nurturing this understanding, they
                  empower individuals to take action, becoming ambassadors for
                  the conservation of wild bees and pollinators.
                </p>
                <footer>
                  <a href="https://appaps.org/" target="_blank"
                    ><i class="fa fa-arrow-right" aria-hidden="true"></i>
                    Appaps</a
                  >
                </footer>
              </div>
            </blockquote>
          </div>
        </div>
        <!-- Footer -->
        <footer id="landing-footer" class="clearfix">
          <div class="row clearfix">
            <p id="copyright">
              <i class="fa fa-copyright fa-1x"></i> 2023, BubbleBees <br />
              Creating a buzz. Making an impact.
            </p>
            <!--Social Icons in Footer-->
            <div class="col-2 social-icons" id="footer-social app-social">
              <!-- social icons here -->
            </div>
            <!--End of Social Icons in Footer-->
          </div>
        </footer>
      </div>
    </div>
    <!-- </div> -->
  </div>
</div>
